import React, { Component } from 'react';
import PropTypes from 'prop-types';

export const HEADING_OPTIONS = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
};

/**
 * @example
 * `<DynamicHeading tag={2} aria-hidden="true">
 *     <span>Hello, World!</span>
 *  </DynamicHeading>`
 */

export default class DynamicHeading extends Component {
  render() {
    const { children, tag, ...other } = this.props;

    switch (tag) {
      case HEADING_OPTIONS.H2:
        return <h2 {...other}>{children}</h2>;
      case HEADING_OPTIONS.H3:
        return <h3 {...other}>{children}</h3>;
      case HEADING_OPTIONS.H4:
        return <h4 {...other}>{children}</h4>;
      case HEADING_OPTIONS.H5:
        return <h5 {...other}>{children}</h5>;
      case HEADING_OPTIONS.H6:
        return <h6 {...other}>{children}</h6>;
      default:
        return <h1 {...other}>{children}</h1>;
    }
  }
}

DynamicHeading.defaultProps = {
  tag: HEADING_OPTIONS.H1,
};

DynamicHeading.propTypes = {
  children: PropTypes.node.isRequired,
  tag: PropTypes.string,
};
