import React, { useCallback, useEffect, useState } from 'react';
import Styles from './cookie-banner.module.css';
import { getCookie, setCookie } from './utils';

const COOKIE_ID = 'cookies-accepted';

export const CookieBanner = ({ onCookie }) => {
  const [isAccepted, setIsAccepted] = useState(false);

  useEffect(() => {
    if (getCookie(COOKIE_ID)) onCookie();
  }, [onCookie]);

  const handleClick = useCallback(() => {
    setCookie(COOKIE_ID, 'true', 365);
    onCookie();
    setIsAccepted(true);
  }, [onCookie]);

  return (
    <div
      className={`nhc-container-right nhc-container-left ${
        Styles.cookieBanner
      } ${isAccepted && Styles.hidden}`}
    >
      <p className="t-body-3">
        We – and our partners – use cookies to deliver our services and to show
        you ads based on your interests. By using our website, you agree to the
        use of cookies as described in our{' '}
        <a
          href="https://www.spotify.com/uk/legal/cookies-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cookie Policy
        </a>
        .
      </p>

      <button type="button" onClick={handleClick} className={Styles.button}>
        Accept
      </button>
    </div>
  );
};
