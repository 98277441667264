import React, { createRef, useContext, useEffect } from 'react';
import { animated, useTrail } from 'react-spring';
import { fadeUpProperties, translateY } from 'common/spring';

import CarnivalTitle from 'components/carnival-title';
import { MinimalFooter } from '../minimal-footer';
import Partners from 'components/svgs/partners';
import PropTypes from 'prop-types';
import Styles from './introduction.module.css';
import ThemeContext from 'common/context.theme';
import AppContext from 'common/context.app';

export function getAnimation(animation, out = false) {
  if (!animation) return {};

  const opacity = animation.opacity;
  const transform = animation.y.interpolate(translateY);

  return {
    opacity,
    transform,
  };
}

const Introduction = props => {
  const elementRef = createRef();
  const { open } = props;
  const reverse = !open;
  const { landingTheme } = useContext(ThemeContext);
  const { isHoldingPageActive } = useContext(AppContext);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;
    if (open) {
      element.removeAttribute('inert');
    } else {
      element.setAttribute('inert', '');
      window.scrollTo(0, 0);
    }
  }, [elementRef, open]);

  const animations = useTrail(10, {
    ...fadeUpProperties,
    y: 1,
    config: { mass: 1, tension: 148, friction: 16 },
  });

  return (
    <div
      className={`nhc-container-left nhc-container-right ${Styles.introduction}`}
      ref={elementRef}
    >
      <div>
        <div style={{ color: landingTheme.color }}>
          <animated.div style={getAnimation(animations[reverse ? 8 : 2])}>
            <p className={Styles.collab}>An online collaboration between</p>
            <Partners className={Styles.partners} />
          </animated.div>
        </div>
        <CarnivalTitle
          theme={landingTheme}
          animations={animations}
          active={props.open}
          onClick={props.onClick}
        />

        <div style={{ color: landingTheme.color }}>
          <animated.div style={getAnimation(animations[reverse ? 8 : 2])}>
            {isHoldingPageActive ? (
              <p className={Styles.comingSoon}>Soon Come</p>
            ) : null}
          </animated.div>
          <MinimalFooter />
        </div>
      </div>
    </div>
  );
};

Introduction.defaultProps = {
  open: false,
  onClick: () => undefined,
};

Introduction.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Introduction;
