import React, { useContext } from 'react';
import AppContext from '../../common/context.app';
import { Dial } from '../svgs/dial';
import Styles from './button-dial.module.css';

export const ButtonDial = () => {
  const { reducedMotion, setReducedMotion } = useContext(AppContext);

  const activeClass = reducedMotion ? '' : Styles.active;

  return (
    <button
      className={`${Styles.button} ${activeClass}`}
      onClick={() => setReducedMotion(!reducedMotion)}
    >
      <Dial/>
      <span className={Styles.label}>{reducedMotion ? 'OFF' : 'ON'}</span>
    </button>
  );
};
