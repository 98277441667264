import React, { useEffect, useRef } from 'react';
import { animated, useTrail } from 'react-spring';
import { fadeUpProperties, translateY } from 'common/spring';

import Link from 'gatsby-link';
import { NAVIGATION_ITEMS } from 'common/navigation';
import Styles from './navigation-mobile.module.css';
import { hasWindow } from 'common/hasWindow';
import { sendTrackingEvent } from '../../common/sendTrackingEvent';

const NavigationMobile = ({ open, onItemClick }) => {
  const elementRef = useRef(null);

  const animation = useTrail(NAVIGATION_ITEMS.length + 2, {
    ...fadeUpProperties,
    config: { mass: 1, tension: 160, friction: 16 },
    reverse: !open,
  });

  useEffect(() => {
    const element = /** @type {HTMLElement} */ (elementRef.current);

    if (element) {
      if (open) {
        element.removeAttribute('inert');
      } else {
        element.setAttribute('inert', '');
        element.setAttribute('aria-hidden', 'false');
      }
    }
  }, [elementRef, open]);

  return (
    <div
      className={Styles.navigationMobile}
      aria-hidden={!open}
      ref={elementRef}
    >
      <div className={Styles.inner}>
        <nav>
          <ul className={Styles.list}>
            {NAVIGATION_ITEMS.map((item, index) => {
              if (index === 0) return null;
              const partiallyActive = hasWindow
                ? window.location.pathname.startsWith(item.route)
                : false;
              const opacity = animation[index].opacity;
              const transform = animation[index].y.interpolate(translateY);
              return (
                <animated.li
                  key={item.route}
                  className={Styles.item}
                  style={{ transform, opacity }}
                >
                  <Link
                    activeClassName={Styles.linkActive}
                    partiallyActive={partiallyActive}
                    className={Styles.link}
                    to={item.route}
                    onClick={() => {
                      sendTrackingEvent('main-navigation', 'click', item.body);
                      onItemClick();
                    }}
                  >
                    {item.body}
                  </Link>
                </animated.li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default NavigationMobile;
