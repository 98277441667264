import { useEffect, useState } from 'react';

const QUERY = '(min-width: 1040px)';

export function useIsDesktop() {
  const [breakpoint, setBreakpoint] = useState(true);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(QUERY);
    const listener = event => setBreakpoint(event.matches);
    mediaQueryList.addListener(listener);
    setBreakpoint(mediaQueryList.matches);

    return () => mediaQueryList.removeListener(listener);
  }, [setBreakpoint]);

  return breakpoint;
}
