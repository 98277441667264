import { DialBigColor, DialBigEmpty } from '../svgs/dial';
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import ThemeContext, { THEMES } from '../../common/context.theme';
import { animated, useTrail } from 'react-spring';
import { fadeUpProperties, translateY } from '../../common/spring';

import AppContext from 'common/context.app';
import { MinimalFooter } from '../minimal-footer';
import Styles from './select-motion-preferences.module.css';
import { Thunder } from '../svgs/thunder';

export const SelectMotionPreferences = () => {
  const { landingTheme, setTheme, setLandingTheme } = useContext(ThemeContext);
  const { setReducedMotion, isHoldingPageActive } = useContext(AppContext);
  const elementRef = useRef(null);

  const animations = useTrail(4, {
    ...fadeUpProperties,
    y: 0,
    config: { mass: 1, tension: 148, friction: 16 },
  });

  const preventBodyTouchMove = useCallback(event => {
    event.preventDefault();
  }, []);

  useEffect(() => {
    // skip if holding page is active
    if (isHoldingPageActive) setReducedMotion(false);
  }, [setReducedMotion, isHoldingPageActive]);

  useLayoutEffect(() => {
    const element = elementRef.current;
    const blockingElements = document.$blockingElements;

    if (blockingElements) blockingElements.push(element);
    window.scrollTo(0, 0);
    document.body.style.overflow = 'hidden';
    document.body.addEventListener('touchmove', preventBodyTouchMove, {
      passive: false,
    });

    setTheme(THEMES.caribbeanGreen);

    // const availableThemes = [
    //   THEMES.parisDaisy,
    //   THEMES.caribbeanGreen,
    //   THEMES.pink,
    //   THEMES.bilobaFlower,
    //   THEMES.outrageousOrange,
    //   THEMES.redOrange,
    //   THEMES.lochinvar,
    //   THEMES.morningGlory,
    // ];

    // Commenting out, just in case we need to add when the full site goes live
    // setLandingTheme(
    //   availableThemes[Math.floor(Math.random() * availableThemes.length)]
    // );

    return () => {
      if (blockingElements && element) blockingElements.remove(element);
      document.body.style.removeProperty('overflow');
      document.body.removeEventListener('touchmove', preventBodyTouchMove);
    };
  }, [setTheme, preventBodyTouchMove, setLandingTheme]);

  return (
    <div
      className={Styles.overlay}
      ref={elementRef}
      style={{ color: landingTheme.color }}
    >
      <div className={Styles.element}>
        <div>
          <h1 className="visually-hidden">Carnival Sounds</h1>
          <div
            className={`${Styles.layout} nhc-container-left nhc-container-right`}
          >
            <div className={Styles.content}>
              <animated.p
                className={Styles.heading}
                style={{
                  transform: animations[0].y.interpolate(translateY),
                  opacity: animations[0].opacity,
                }}
              >
                <Thunder />
                This site contains flashing images
              </animated.p>
              <div className={Styles.buttonContainer}>
                <animated.button
                  className={Styles.button}
                  onClick={() => setReducedMotion(true)}
                  style={{
                    transform: animations[1].y.interpolate(translateY),
                    opacity: animations[1].opacity,
                    color: landingTheme.color,
                  }}
                >
                  <DialBigEmpty />
                  Turn off
                </animated.button>
                <animated.div
                  className={Styles.divider}
                  style={{
                    transform: animations[1].y.interpolate(translateY),
                    opacity: animations[1].opacity,
                  }}
                />
                <animated.button
                  className={Styles.button}
                  onClick={() => setReducedMotion(false)}
                  style={{
                    transform: animations[2].y.interpolate(translateY),
                    opacity: animations[2].opacity,
                    color: landingTheme.color,
                  }}
                >
                  <DialBigColor />
                  Turn on
                </animated.button>
              </div>
            </div>
            <MinimalFooter />
          </div>
        </div>
      </div>
    </div>
  );
};
