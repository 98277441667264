import React, { useContext } from 'react';

import { ITEMS } from '../footer';
import Styles from './style.module.css';
import ThemeContext from 'common/context.theme';
import { sendTrackingEvent } from '../../common/sendTrackingEvent';

export function MinimalFooter() {
  const { landingTheme } = useContext(ThemeContext);

  return (
    <div className={Styles.layout} style={{ color: landingTheme.color }}>
      <nav>
        <ul className={Styles.list}>
          {ITEMS.map(({ body, url }) => (
            <li key={body} className={Styles.item}>
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => sendTrackingEvent('footer', 'click', body)}
              >
                <span>{body}</span>
              </a>
            </li>
          ))}
        </ul>
      </nav>
      <span className={Styles.copyright}>© 2023 Spotify AB</span>
    </div>
  );
}
