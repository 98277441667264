import PropTypes from 'prop-types';
import React from 'react';
import Styles from './carnival-title.module.css';
import { animated } from 'react-spring';
import { getAnimation } from '../introduction';

const CarnivalHeadline = props => {
  const reverse = !props.active;

  const { animations } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 1018 254"
      aria-labelledby="headline-carnival-title"
      className={Styles.headline}
    >
      <title id="headline-carnival-title">Carnival sounds</title>
      <g fill="currentColor">
        <animated.path
          style={getAnimation(animations[7], reverse)}
          d="M859.5 128h46.9c10.7 0 18.1 7 18.5 17.6.3 6.4 0 12.9.1 19.2 0 2.6-.9 3.3-3.3 3.3-15.2 0-30.2-.2-45.3 0-2.6 0-3.5-1-3.5-3.5 0-7.6-.1-7.6-7.7-7.6H851c-2 0-4.2-.3-4.7 2.6-.8 3.9.3 6.3 3.4 7.3 5.8 1.7 11.7 3.2 17.5 4.8 14.6 4 29.3 7.7 44 11.8 8.8 2.4 13.7 8.9 13.7 18V228c0 10.2-7.8 18-18 18h-85.7c-4.2 0-8.4.3-12.5-.3-8.6-1.5-14.4-9-14.6-17.7 0-7 .2-13.8 0-20.6 0-2.5.8-3.2 3.3-3.2h45.2c2.6 0 3.8.7 3.5 3.4v6.3c0 2 .9 2.7 2.9 2.7h21.2c.8 0 2.2-.1 2.2-.4.2-2.8.6-5.8.5-8.6 0-.9-1.6-2.2-2.7-2.5-13.5-3.8-27.2-7.3-40.7-11-7.3-1.8-14.6-3.7-21.7-5.9-9.3-2.8-13.5-8.6-13.7-18.5 0-8.4-.1-16.6.2-25 .2-9.8 7.8-17 17.7-17l47.5.1z"
        />
        <animated.path
          style={getAnimation(animations[6], reverse)}
          d="M653 246.7v-3.8c0-36.9 0-73.8-.2-110.6 0-3.5 1-4.4 4.3-4.4 36 .2 71.8.2 107.7.2 11.6 0 19 7.1 19 18.6v81.6c0 8.8-4 14.6-12.2 17.9-1.1.4-2.2.6-3.3.6H655l-2-.1zm51.8-59.4c0 8.9.2 17.7 0 26.6 0 2.8 1 3.8 3.8 3.6h19.6c2.6 0 3.8-.7 3.7-3.4V161c0-2.6-1-3.2-3.4-3.2h-19.9c-3-.2-3.8 1-3.8 3.9.2 8.6 0 17.1 0 25.7z"
        />
        <animated.path
          style={getAnimation(animations[5], reverse)}
          d="M553.4 169.3c-2.7-1-3.1 1-3.1 3.6v73h-52v-3.5c0-36.8 0-73.5-.2-110.3 0-3.5 1.1-4.3 4.3-4.3l56.8.1c1.5 0 3.8 1 4.3 2.2 6 13.1 12 26 17.3 39.5 4 9.8 4.5 20.5 5.1 31 0 .8 0 1.8.2 2.3.5.8 1.5 1.8 2.3 1.8.6 0 1.6-1.1 2-2 .4-.9.1-2 .1-3v-67.8c0-3.3 1-4.4 4.2-4.2H639c2.8 0 4 .6 4 3.8-.2 37-.2 73.9 0 110.9 0 3-.9 3.9-3.9 3.9l-57.4-.1c-1.5 0-3.9-1-4.4-2.2-6-13.2-12.5-26.2-17-39.9-3.4-10.8-4.5-22.7-6.8-34.8z"
        />
        <animated.path
          style={getAnimation(animations[4], reverse)}
          d="M409.2 217.5h21.5c4.9.2 5.3-.1 5.3-4.8v-79c0-1.7.2-3.5.4-5.4H488l.2 2v97.2c0 10-4.5 16-13.7 19-1.5.3-3 .5-4.6.5-31.5 0-63-.2-94.4.1a18 18 0 01-18.3-18.2c.2-18.1 0-36.2 0-54.3v-46.4H405c4.6 0 4.6 0 4.6 4.5v81.2c-.3 1-.3 2.1-.3 3.6z"
        />

        <animated.g
          style={getAnimation(animations[2])}
          className={Styles.burstInner}
        >
          {/* just a regular circle */}
          {/* <path
            fill={props.sun}
            d="M310.9,243.2c30.3-13.2,44.1-48.4,30.8-78.7s-48.4-44.1-78.7-30.8c-30.3,13.2-44.1,48.4-30.8,78.7
            C245.5,242.7,280.7,256.5,310.9,243.2z"
          /> */}
          {/* sun with spikes */}
          <path
            fill={props.sun}
            d="M352 188.6c-11.8 3.6-12.2 7.2-1.3 13-12.3 1.1-13.3 4.5-3.8 12.3-12.2-1.5-13.9 1.6-6 11-11.7-3.8-13.9-1.1-8 9.6-10.7-6-13.3-3.8-9.5 8-9.5-8-12.5-6.3-11 6-7.9-9.6-11.2-8.6-12.3 3.7-5.8-10.8-9.4-10.5-13 1.4-3.8-11.9-7.3-12.2-13.2-1.4-1-12.3-4.4-13.3-12.2-3.8 1.4-12.2-1.6-13.8-11-6 3.7-11.7 1-13.8-9.7-7.9 6-10.8 3.8-13.4-7.9-9.6 7.9-9.4 6.2-12.5-6-11 9.6-7.8 8.5-11.2-3.8-12.2 10.9-5.9 10.5-9.5-1.3-13.1 11.8-3.7 12.2-7.3 1.3-13.2 12.3-1 13.4-4.4 3.8-12.2 12.3 1.5 13.9-1.6 6-11 11.7 3.8 14 1.1 8-9.6 10.7 6 13.4 3.8 9.6-8 9.4 8 12.4 6.3 11-6 7.8 9.6 11.1 8.6 12.2-3.7 5.8 10.8 9.4 10.5 13.1-1.4 3.7 11.9 7.3 12.2 13.1 1.4 1.1 12.3 4.4 13.3 12.2 3.8-1.4 12.2 1.6 13.8 11 6-3.7 11.7-1 13.9 9.7 7.9-6 10.8-3.8 13.4 8 9.6-8 9.4-6.3 12.5 5.9 11-9.5 7.8-8.5 11.2 3.8 12.2-10.9 5.9-10.5 9.5 1.3 13.2z"
          ></path>
        </animated.g>
        <animated.path
          style={getAnimation(animations[1], reverse)}
          d="M153.3 128H200c10.4 0 17.7 6.6 18.3 17.1.4 6.8.2 13.5.3 20.3 0 2.3-1 2.8-3.1 2.8h-46c-2.4 0-3-1-3.2-3.2-.4-7.9-.4-7.9-8.4-7.9h-13.7c-2 0-4.2-.8-4.6 2.5-.6 4.3 0 6.3 3.3 7.3 5.2 1.6 10.4 2.8 15.6 4.3 15.2 4 30.6 8 46 12.3 9 2.4 13.8 8.9 13.8 18.2v26.4c0 10.4-8.2 18.6-18.6 18.5l-54.3-.3c-12.5 0-24.8.1-37.2.3-12.7.1-20.7-7.7-20.7-20.4 0-6.3.2-12.5 0-18.5 0-2.5.6-3.4 3.3-3.4h45.5c2.3 0 3.4.7 3.1 3v1.4c0 8.1 0 8.1 8.3 8.1h15.8c2.3 0 2.8-.8 3-3 0-7.6 0-7.6-7.5-9.7-18.5-5-37.1-9.8-55.6-15-11.8-3.2-15.9-8.6-15.9-20.9 0-7.8 0-15.8.2-23.6.4-9.6 8-16.7 17.7-16.7h48z"
        />
        <animated.path
          style={getAnimation(animations[0], reverse)}
          d="M963.8.1v84.5c0 4.4 0 4.6 4.5 4.6h49.7v29.4l-3.4.2h-98.2c-4.7 0-4.7 0-4.7-4.8 0-36.5 0-73-.2-109.5 0-3.4.9-4.5 4.4-4.5l44.1.1h3.8z"
        />
        <animated.path
          style={getAnimation(animations[0], reverse)}
          d="M759.8 118.2l15.6-68c3.5-15.3 7.1-30.7 10.5-46.3.5-2.7 1.5-3.9 4.6-3.9h83c3 0 4.3 1 5 3.8l22.6 100c1.1 4.7 2 9.3 3.3 14.3-1.8.1-3.3.5-4.8.5-14.4 0-28.7 0-43.2.2-3.2 0-4.6-1.3-5.1-4.4l-2-10.2c-.4-2.2-1.5-3.3-4-3.3-8.7.2-17.5.2-26.3 0-2.3 0-3.5 1-4 3.3-.6 3.9-1.4 8-2.6 11.9-.3 1-1.8 2.5-2.7 2.5-16 .2-32 .2-48 0-.5 0-1-.3-1.9-.4zM842 73.8c-.3-1.5-.6-2.6-.7-3.8L834.5 33c-.2-1.6 0-3.2-2.4-3.2s-2.4 1.7-2.6 3.5c-.1 3-.2 6-.8 9l-6 28c-.2 1-.2 2.3-.3 3.5l19.6-.1z"
        />
        <animated.path
          style={getAnimation(animations[1], reverse)}
          d="M638.8.7l3-.2h45.3c2.7 0 4 .7 4.8 3.6 4 17.7 8.5 35.2 12.4 53 1 5 1 10.3 1.3 15.5.2.9 0 2 .3 2.7.6.9 1.6 1.7 2.4 2.5.7-1 2-1.7 2-2.8.7-6.1.5-12.2 1.8-18.1 3-14 6.5-28 9.8-41.9.9-4 2.1-7.8 3-11.8.5-1.7 1.3-2.7 3.2-2.7h48.2l1.6.3-5.2 19.9c-8.7 31.9-17.2 63.6-25.7 95.5-.7 2.6-2 3.1-4.2 3.1a6070 6070 0 00-70.1 0c-2.6 0-3.4-.7-4-3.1L639 2.7l-.2-2z"
        />
        <animated.path
          style={getAnimation(animations[2], reverse)}
          d="M632.6 118.9h-51V.4h51V119z"
        />
        <animated.path
          style={getAnimation(animations[3], reverse)}
          d="M482.3 41.9c-3.5-1.1-4 .5-4 3.4v73.1h-51.8l-.1-3.6-.1-110.2c0-3.4.8-4.5 4.3-4.5 18.8.2 37.5.2 56.3 0 3 0 4.5 1 5.7 3.7 5.4 12.8 11.3 25.4 16.5 38.4a84.8 84.8 0 015 29c0 1.4-.2 2.7.2 4 .3.9 1.4 1.5 2 2.3.7-.8 1.8-1.3 2.2-2.2.4-.9.2-2 .2-3V5c0-4.8 0-4.8 5-4.8h43.9c2.4 0 3.3.5 3.3 3v112.3c0 2.6-.9 3.3-3.3 3.3l-57.7-.1c-1.5 0-4-1-4.5-2.2-6-13.8-12.7-27.3-17.5-41.5-3-8.5-3.4-18-5-27.1l-.6-6.1z"
        />
        <animated.path
          style={getAnimation(animations[4], reverse)}
          d="M420.6 118.3c-1.4.2-2.5.2-3.4.2-15.7 0-31.2-.2-46.9.1-3.2 0-4.6-1-5.4-4-3.2-10.7-6.9-21.4-10.3-32-.9-2.9-2.5-4.4-5.6-4.1-2.6.1-5.2.3-7.7 0-3.1-.4-4 1-4 3.8V118.5l-3.2.1c-15.1 0-30.1 0-45.3.2-2.4 0-3.3-.7-3.3-3.3V3.6c0-2.5 1-3.2 3.4-3.2L398 .5c10.5 0 18 7.7 18 18.3v36.7c0 7.7-6 15-13.8 16.5-.8.1-1.7.1-2.3.6-.6.6-1.4 1.6-1.6 2.5 0 .7 1 2 1.6 2 7.3 1.5 10.6 6.4 12.5 13.1 2.1 8.3 4.8 16.4 7.4 24.5.3.8.4 2.2.7 3.6zm-70.1-70.9h10.4c2.3.2 3.3-.5 3.2-3V32.7c0-2-.8-2.7-2.8-2.7H340c-2.2 0-3.2.8-3 3v11.3c0 2.3 1 3.3 3.2 3.1h10.2z"
        />
        <animated.path
          style={getAnimation(animations[5], reverse)}
          d="M280.3 118.8H232c-2.7 0-4.2-1-4.6-3.7-.6-3.6-1.5-7.3-2.2-11-.4-2.1-1.3-3.2-3.8-3.2-8.7.2-17.5.2-26.3 0-2.6 0-3.5 1-4 3.4-.6 4-1.4 7.7-2.5 11.5-.3 1-2 2.5-3 2.7l-47.5.1c-.6 0-1.1-.3-2-.4 1.4-6.9 3-13.6 4.5-20.3 7-31.4 14.4-62.8 21.5-94.1.6-2.6 1.5-3.7 4.5-3.7 27.8.2 55.5.2 83.3 0 3.1 0 4 1.3 4.8 4l24 106 1.6 8.7zm-82.1-45h19.7l-.2-2.9c-2.8-12.8-6.7-25.4-7-38.6 0-.8-1.7-1.6-2.5-2.3-.8 1-2 1.7-2.2 2.7-1.6 8.6-3 17.4-4.4 26l-3.4 15z"
        />
        <animated.path
          style={getAnimation(animations[6], reverse)}
          d="M130.8 72.7c0 10.3.3 20.1-.2 30-.4 9.8-9.5 16.7-17.8 16.6a3723 3723 0 00-95.1 0c-7.8.1-17.3-6.4-17.6-16.8L0 84.3v-63c0-3 .3-6.2 1.2-9C3.5 4.7 9.7.4 18.1.4h95.3A18 18 0 01131 17.7v19.9c0 2.1-.7 3-2.9 3-15.3 0-30.5-.2-46 0-2.2 0-3-.9-3-3.2 0-7.3-.2-7.3-7.6-7.3-5.4 0-10.8.2-16.2 0-2.4 0-3.1 1-3.1 3.3v53.2c0 2.3.7 3.5 3.2 3.4 6.8-.2 13.5-.2 20.3 0 2.7 0 3.5-1.1 3.5-3.7v-11c0-2 .7-2.9 2.7-2.9 15.8.2 31.8.2 47.6.2.1-.2.6 0 1.2 0z"
        />
      </g>
    </svg>
  );
};

CarnivalHeadline.defaultProps = {};

CarnivalHeadline.propTypes = {
  active: PropTypes.bool,
  animations: PropTypes.array,
};

export default CarnivalHeadline;
