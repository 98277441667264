import React from 'react';

export const Dial = props => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 34 32"
  >
    <path fill="#F59B23" d="M16.93 0h-.75v5.21h.75V0z" />
    <path fill="#FAE62D" d="M12.59.52l-.73.2 1.35 5.03.73-.2L12.59.52z" />
    <path fill="#FAE62D" d="M8.54 2.14l-.65.38 2.6 4.5.66-.37-2.6-4.5z" />
    <path fill="#FAE62D" d="M5.04 4.78l-.53.53 3.68 3.68.53-.53-3.68-3.68z" />
    <path fill="#FAE62D" d="M2.33 8.21l-.37.65 4.5 2.6.38-.65-4.51-2.6z" />
    <path fill="#FAE62D" d="M.6 12.23l-.19.73 5.04 1.35.19-.73-5.03-1.35z" />
    <path fill="#1FC58E" d="M5.21 16.56H0v.75h5.21v-.75z" />
    <path fill="#1FC58E" d="M5.56 19.56L.53 20.9l.2.72 5.02-1.35-.2-.72z" />
    <path fill="#1FC58E" d="M6.67 22.34l-4.51 2.6.37.65L7.04 23l-.37-.65z" />
    <path fill="#1FC58E" d="M8.45 24.77l-3.68 3.68.53.53 3.68-3.68-.53-.53z" />
    <path fill="#1FC58E" d="M10.82 26.64l-2.6 4.51.65.38 2.6-4.51-.65-.38z" />
    <path fill="#FF4632" d="M25.3 24.51l-.53.53 3.69 3.69.53-.53-3.69-3.69z" />
    <path fill="#FF4632" d="M27 22.01l-.37.65 4.52 2.6.37-.64-4.51-2.6z" />
    <path fill="#FF4632" d="M28.02 19.2l-.19.72 5.03 1.35.2-.72-5.04-1.35z" />
    <path fill="#FF4632" d="M33.49 16.18h-5.21v.75h5.21v-.75z" />
    <path fill="#F59B23" d="M32.78 11.88l-5.03 1.35.2.72 5.02-1.35-.2-.72z" />
    <path fill="#F59B23" d="M30.97 7.88l-4.51 2.6.37.65 4.51-2.6-.37-.65z" />
    <path fill="#F59B23" d="M28.19 4.5L24.5 8.2l.54.53 3.68-3.68-.53-.53z" />
    <path fill="#F59B23" d="M24.64 1.96l-2.6 4.51.64.38 2.6-4.52-.64-.37z" />
    <path fill="#F59B23" d="M20.53.42l-1.35 5.03.72.2L21.25.6l-.72-.2z" />
    <path fill="#FF4632" d="M18.09 17.96l-.65.37 7.5 13 .65-.38-7.5-13z" />
  </svg>
);

export const DialBigColor = props => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 100 100">
    <rect
      width="98"
      height="98"
      x="1"
      y="1"
      fill="#000"
      stroke="#000"
      strokeWidth="2"
      rx="49"
    />
    <path fill="#F59B23" d="M50.38 14h-1.61v11.2h1.6V14z" />
    <path
      fill="#FAE62D"
      d="M41.05 15.12l-1.56.42 2.9 10.81 1.55-.42-2.9-10.81zM32.35 18.6l-1.4.81 5.6 9.7 1.4-.81-5.6-9.7zM24.83 24.26l-1.14 1.14 7.91 7.92 1.14-1.14-7.91-7.92zM19 31.64l-.8 1.4 9.7 5.6.8-1.4-9.7-5.6zM15.3 40.29l-.41 1.55 10.81 2.9.42-1.55-10.81-2.9zM25.2 49.59H14v1.6h11.2v-1.6z"
    />
    <path
      fill="#1FC58E"
      d="M25.95 56.03l-10.82 2.9.42 1.55 10.81-2.9-.41-1.55zM28.33 62l-9.7 5.6.8 1.4 9.7-5.6-.8-1.4zM32.16 67.23l-7.91 7.91 1.13 1.14 7.92-7.91-1.14-1.14zM37.26 71.24l-5.6 9.7 1.4.8 5.6-9.69-1.4-.8z"
    />
    <path
      fill="#FF4632"
      d="M52.93 52.7l-1.4.8L67.6 81.32l1.4-.8-16.07-27.83zM68.37 66.68l-1.14 1.14 7.92 7.91 1.14-1.14-7.92-7.91zM72.04 61.3l-.81 1.4 9.7 5.6.8-1.4-9.7-5.6zM74.22 55.25l-.42 1.56 10.82 2.9.41-1.56-10.81-2.9zM85.97 48.77h-11.2v1.61h11.2v-1.61z"
    />
    <path
      fill="#F59B23"
      d="M84.44 39.52l-10.82 2.9.42 1.56 10.82-2.9-.42-1.56zM80.55 30.93l-9.7 5.6.8 1.4 9.7-5.6-.8-1.4zM74.57 23.68l-7.91 7.92 1.14 1.14 7.91-7.92-1.14-1.14zM66.95 18.21l-5.6 9.7 1.4.8 5.6-9.7-1.4-.8zM58.1 14.9l-2.9 10.8 1.56.42 2.9-10.81-1.55-.42z"
    />
  </svg>
);

export const DialBigEmpty = props => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 100 100">
    <rect
      width="99"
      height="99"
      x=".5"
      y=".5"
      stroke="currentColor"
      rx="49.5"
    />
    <path
      fill="currentColor"
      d="M50.38 14h-1.61v11.2h1.6V14zM41.05 15.12l-1.56.42 2.9 10.81 1.55-.42-2.9-10.81zM32.35 18.6l-1.4.81 5.6 9.7 1.4-.81-5.6-9.7zM24.83 24.26l-1.14 1.14 7.91 7.92 1.14-1.14-7.91-7.92zM19 31.64l-.8 1.4 9.7 5.6.8-1.4-9.7-5.6zM15.3 40.29l-.41 1.55 10.81 2.9.42-1.55-10.81-2.9zM25.2 49.59H14v1.6h11.2v-1.6zM25.95 56.03l-10.82 2.9.42 1.55 10.81-2.9-.41-1.55zM28.33 62l-9.7 5.6.8 1.4 9.7-5.6-.8-1.4zM32.16 67.23l-7.91 7.91 1.13 1.14 7.92-7.91-1.14-1.14zM48 52.66L31.65 80.94l1.4.8 16.33-28.28-1.4-.8zM63.4 70.82l-1.4.8 5.6 9.7 1.4-.8-5.6-9.7zM68.37 66.68l-1.14 1.14 7.92 7.91 1.14-1.14-7.92-7.91zM72.04 61.3l-.81 1.4 9.7 5.6.8-1.4-9.7-5.6zM74.22 55.25l-.42 1.56 10.82 2.9.41-1.56-10.81-2.9zM85.97 48.77h-11.2v1.61h11.2v-1.61zM84.44 39.52l-10.82 2.9.42 1.56 10.82-2.9-.42-1.56zM80.55 30.93l-9.7 5.6.8 1.4 9.7-5.6-.8-1.4zM74.57 23.68l-7.91 7.92 1.14 1.14 7.91-7.92-1.14-1.14zM66.95 18.21l-5.6 9.7 1.4.8 5.6-9.7-1.4-.8zM58.1 14.9l-2.9 10.8 1.56.42 2.9-10.81-1.55-.42z"
    />
  </svg>
);
