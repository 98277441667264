export const translate = (x, y, unit = 'px') => `translate3d(${x}${unit}, ${y !== 'undefined' ? y : x}${unit}, 0)`;
export const translateX = x => translate(x, 0);
export const translateY = y => translate(0, y);
export const scale = (x, y) => `scale(${x}, ${y ? y : x})`;
export const scaleX = x => scale(x, 1);
export const scaleY = y => scale(1, y);
export const opacity = opacity => `${opacity}`;
export const rotation = rotation => `rotate(${rotation}deg)`;


export const fadeUpProperties = {
  delay: 100,
  config: { mass: 1, tension: 580, friction: 48 },
  from: { opacity: 0, y: 16 },
  to: { y: 0, opacity: 1 }
};
