import React, { useCallback, useContext, useEffect, useRef } from 'react';

import AppContext from 'common/context.app';
import EventsShape from 'images/shapes/articles.svg';
import HomeShape from 'images/shapes/home.svg';
import PodcastsShape from 'images/shapes/podcasts.svg';
import SoundSystemsShape from 'images/shapes/soundsystems.svg';
import TakeoversShape from 'images/shapes/takeovers.svg';
import { rotation } from 'common/spring';

function getShape(page) {
  switch (page) {
    case '/articles/':
      return EventsShape;
    case '/podcasts/':
      return PodcastsShape;
    case '/sound-systems/':
      return SoundSystemsShape;
    case '/playlist-takeovers/':
      return TakeoversShape;
    default:
      return HomeShape;
  }
}

const Badge = props => {
  const { currentPage, reducedMotion } = useContext(AppContext);
  const elementRef = useRef(null);

  const handleScroll = useCallback(() => {
    const element = /** @type {HTMLElement} */ (elementRef.current);

    if (element && !reducedMotion) {
      element.style.transform = rotation(window.scrollY / 10);
    }
  }, [elementRef, reducedMotion]);

  useEffect(() => {
    if (!reducedMotion) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [elementRef, reducedMotion, handleScroll]);

  return (
    <div ref={elementRef}>
      <img src={getShape(currentPage)} alt="Back to home" aria-hidden="true" />
    </div>
  );
};

export default Badge;
