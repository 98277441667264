import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { APP_ICONS } from './app-icons';

function SEO({ description, lang, meta, title, author, siteurl, image }) {
  const socialImage = image || '/images/social.jpg';
  const socialTitle = title || 'Carnival Sounds';
  const socialAuthor = author || '@spotify';
  const socialDescription = description || 'Spotify and Notting Hill Carnival have partnered together to bring this year\'s carnival from the speakers in the streets to the speakers in your home.';
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || 'Carnival Sounds'}
      titleTemplate={title ? `%s | Carnival Sounds` : 'Carnival Sounds'}
    >
      <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover"/>
      <meta name="description" content={socialDescription} />
      <meta name="keywords" content={'Notting Hill Carnival, Spotify, Carnival'} />
      <meta name="applicable-device" content="pc,mobile" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent"/>
      <meta name="apple-mobile-web-app-title" content={socialTitle}/>

      {APP_ICONS.map(({ src, type, sizes, rel }) => (
        <link key={src} rel={rel} type={type} sizes={sizes} href={src} />
      ))}

      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={socialTitle} />
      <meta property="og:title" content={socialTitle} />
      <meta property="og:description" content={socialDescription} />
      <meta property="og:image" content={socialImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={socialTitle} />
      <meta name="twitter:description" content={socialDescription} />
      <meta name="twitter:image" content={socialImage} />
      <meta name="twitter:image:alt" content={socialTitle} />
      <meta name="twitter:creator" content={socialAuthor} />
      <meta name="twitter:site" content={socialAuthor} />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
