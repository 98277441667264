import React, { useContext, useEffect, useRef } from 'react';

import AppContext from '../../common/context.app';
import BackgroundNoise from './bgs';
import Noise from './noise.png';
import PropTypes from 'prop-types';
import Styles from './background.module.css';

const Background = props => {
  const { reducedMotion, hasBackgroundNoiseTexture } = useContext(AppContext);
  const noiseRef = useRef();
  const noiseElementRef = useRef();

  // Get noise element. then populate noiseRef.
  useEffect(() => {
    if (noiseElementRef.current && hasBackgroundNoiseTexture) {
      noiseRef.current = new BackgroundNoise(noiseElementRef.current);
    }
  }, [noiseElementRef, hasBackgroundNoiseTexture]);

  // When noise ref is populated, set it to active based on reducedmotion.
  useEffect(() => {
    if (noiseRef.current) {
      noiseRef.current.active = !reducedMotion;
    }
  }, [noiseRef, reducedMotion]);

  return (
    <div
      className={Styles.background}
      style={{ backgroundColor: props.theme.backgroundColor }}
    >
      {hasBackgroundNoiseTexture && (
        <div ref={noiseElementRef} className={Styles.noise}>
          <div style={{ background: `url(${Noise})` }} />
        </div>
      )}
    </div>
  );
};

Background.defaultProps = {
  theme: {
    backgroundColor: 'var(--caribbean-green)',
    color: 'var(--yellow)',
    sun: 'var(--red-orange)',
  },
};

Background.propTypes = {
  theme: PropTypes.object,
};

export default Background;
