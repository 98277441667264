// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-playlist-takeovers-js": () => import("./../../src/pages/playlist-takeovers.js" /* webpackChunkName: "component---src-pages-playlist-takeovers-js" */),
  "component---src-pages-podcasts-js": () => import("./../../src/pages/podcasts.js" /* webpackChunkName: "component---src-pages-podcasts-js" */),
  "component---src-pages-sound-systems-js": () => import("./../../src/pages/sound-systems.js" /* webpackChunkName: "component---src-pages-sound-systems-js" */),
  "component---src-templates-article-js": () => import("./../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-soundsystems-js": () => import("./../../src/templates/soundsystems.js" /* webpackChunkName: "component---src-templates-soundsystems-js" */)
}

