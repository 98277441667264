import React from 'react';
import Link from 'gatsby-link';
import { animated, useTrail } from 'react-spring';
import PropTypes from 'prop-types';

import { NAVIGATION_ITEMS } from 'common/navigation';
import { fadeUpProperties } from 'common/spring';
import { hasWindow } from 'common/hasWindow';
import { sendTrackingEvent } from 'common/sendTrackingEvent';
import { translateX } from 'common/spring';
import { ButtonDial } from '../button-dial';

import Badge from './badge';
import Styles from './navigation.module.css';

const Navigation = props => {
  const animation = useTrail(NAVIGATION_ITEMS.length, {
    ...fadeUpProperties,
  });

  return (
    <nav className={`${Styles.navigation} ${props.className}`}>
      <Link to="/" className={Styles.badge} onClick={() => sendTrackingEvent('main-navigation', 'click', 'badge')}>
        <Badge />
      </Link>
      <ul className={Styles.list}>
        {NAVIGATION_ITEMS.map((item, index) => {
          if (index === 0) return null;
          const partiallyActive = hasWindow ? window.location.pathname.startsWith(item.route) : false;
          const opacity = animation[index].opacity;
          const transform = animation[index].y.interpolate(translateX);
          return (
            <animated.li
              key={item.route}
              className={Styles.item}
              style={{ transform, opacity }}
            >
              <Link
                to={item.route}
                className={Styles.link}
                activeClassName={Styles.active}
                partiallyActive={partiallyActive}
                onClick={() => sendTrackingEvent('main-navigation', 'click', item.body)}
              >
                {item.body}
              </Link>
            </animated.li>
          );
        })}
      </ul>
      <animated.div
        style={{
          opacity: animation[NAVIGATION_ITEMS.length - 1].opacity,
        }}
      >
        <ButtonDial/>
      </animated.div>
    </nav>
  );
};

Navigation.defaultProps = {
  className: '',
};

Navigation.propTypes = {
  className: PropTypes.string,
};

export default Navigation;
