export const NAVIGATION_ITEMS = [
  {
    route: '/',
    text: 'Home',
  },
  {
    route: '/sound-systems/',
    body: 'Sound Systems',
  },
  {
    route: '/playlist-takeovers/',
    body: 'Takeovers',
  },
  {
    route: '/podcasts/',
    body: 'Podcasts',
  },
];
