import React from 'react';

export function Thunder() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 60 60">
      <g fill="currentColor" clipPath="url(#clip0)">
        <path d="M35 4L16 31h12l-3 24 19-32H32l3-19z" />
        <path d="M24 60l3-28H14L37 0l-4 22h13L24 60zm-6-30h12l-3 21 16-27H30l3-16-15 22z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h60v60H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
