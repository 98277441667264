import 'css/global/global.css';

import AppContext, { AppContextProvider } from 'common/context.app';
import React, { useContext, useEffect, useState } from 'react';
import ThemeContext, {
  ThemeContextProvider,
  THEMES,
} from 'common/context.theme';
import { graphql, useStaticQuery } from 'gatsby';

import Background from 'components/background';
import { CookieBanner } from 'components/cookie-banner';
import Footer from 'components/footer';
import Header from 'components/header';
import Introduction from 'components/introduction';
import { MAIN_CONTENT_ID } from 'common/a11y';
import Navigation from 'components/navigation';
import { SelectMotionPreferences } from '../select-motion-preferences';
import Seo from 'components/seo';
import SkipToMainContent from 'components/skip-to-main-content';
import Styles from './root.module.css';
import { useIsDesktop } from 'common/hooks/useIsDesktop';
import { useInterval } from '../../common/hooks/useInterval';

const availableThemes = [
  THEMES.parisDaisy,
  THEMES.caribbeanGreen,
  THEMES.pink,
  THEMES.bilobaFlower,
  THEMES.outrageousOrange,
  THEMES.redOrange,
  THEMES.lochinvar,
  THEMES.morningGlory,
];

const Shell = props => {
  const { theme, landingTheme, setLandingTheme } = useContext(ThemeContext);
  const {
    introActive,
    setIntroActive,
    reducedMotion,
    isHoldingPageActive,
  } = useContext(AppContext);
  const [cookie, setCookie] = useState(false);
  const isDesktop = useIsDesktop();

  /**
   * AppContext.reducedMotion is initially a string,
   *    which we later on populate with a boolean.
   * We use the type to determine whether reducedMotion has been set by the
   *    user or not.
   */
  const queryReducedMotion = typeof reducedMotion === 'string';
  const isLandingPageBackground = queryReducedMotion || introActive;
  const backgroundTheme = isLandingPageBackground ? landingTheme : theme;

  useInterval(() => {
    if (!queryReducedMotion) {
      setLandingTheme(
        availableThemes[Math.floor(Math.random() * availableThemes.length)]
      );
    }
  }, 5000);

  return (
    <div className={Styles.root} style={theme}>
      <Background theme={backgroundTheme} />
      <div className="visually-hidden" aria-hidden="true">
        <p style={{ fontFamily: 'Druk Condensed' }}>1</p>
        <p style={{ fontFamily: 'Druk' }}>1</p>
        <p style={{ fontFamily: 'RT Alias Fine' }}>1</p>
      </div>
      {!cookie && <CookieBanner onCookie={() => setCookie(true)} />}

      {queryReducedMotion && <SelectMotionPreferences />}

      <div className={Styles.hideContent} aria-hidden={queryReducedMotion}>
        {!introActive && <SkipToMainContent contentId={MAIN_CONTENT_ID} />}

        <div className={Styles.content}>
          {!queryReducedMotion && !introActive && (
            <div className={Styles.header}>
              <Header />
            </div>
          )}

          {isDesktop && !queryReducedMotion && !introActive && (
            <div className={Styles.nav}>
              <Navigation className={Styles.navInner} />
            </div>
          )}

          <main className={Styles.main} id={MAIN_CONTENT_ID} tabIndex={-1}>
            <div style={{ display: introActive ? 'none' : 'block' }}>
              {!isHoldingPageActive && props.children}
            </div>
            {!introActive && <Footer />}
          </main>
        </div>

        {introActive && !queryReducedMotion && (
          <Introduction
            onClick={() => !isHoldingPageActive && setIntroActive(false)}
            open={introActive}
          />
        )}
      </div>
    </div>
  );
};

const Root = props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          siteurl
        }
      }
    }
  `);

  const { url } = props;
  const { description, author, siteurl } = data.site.siteMetadata;

  useEffect(() => {
    import('focus-visible').then(() => undefined);
    import('wicg-inert').then(() => undefined);
    import('blocking-elements').then(() => undefined);
  }, []);

  return (
    <AppContextProvider currentPage={url}>
      <ThemeContextProvider>
        <Seo
          lang="en"
          author={author}
          description={description}
          title={''}
          siteurl={siteurl}
        />
        <Shell {...props} />
      </ThemeContextProvider>
    </AppContextProvider>
  );
};

export default Root;
