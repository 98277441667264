module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://carnival.withspotify.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-120318753-19"],"pluginConfig":{"head":false,"respectDNT":true,"delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Carnival Sounds","short_name":"Carnival","start_url":"/","background_color":"#191414","theme_color":"#1fc58e","display":"standalone","legacy":false,"icons":[{"src":"/favicons/appicon.svg","type":"image/svg+xml"},{"src":"/favicons/favicon.ico","type":"image/x-icon","sizes":"48x48"},{"src":"/favicons/favicon-16x16.png","type":"image/png","sizes":"16x16"},{"src":"/favicons/favicon-32x32.png","type":"image/png","sizes":"32x32"},{"src":"/favicons/favicon-48x48.png","type":"image/png","sizes":"48x48"},{"src":"/favicons/android-chrome-192x192.png","type":"image/png","sizes":"192x192"},{"src":"/favicons/android-chrome-512x512.png","type":"image/png","sizes":"512x512"}],"cache_busting_mode":"query","include_favicon":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
