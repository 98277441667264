import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { animated, useTrail } from 'react-spring';
import Link from 'gatsby-link';

import { NAVIGATION_ITEMS } from 'common/navigation';
import { useIsDesktop } from 'common/hooks/useIsDesktop';
import { useIsTablet } from 'common/hooks/useIsTablet';

import NavigationMobile from 'components/navigation-mobile';
import Logo from 'components/svgs/logo';
import Partners from 'components/svgs/partners';
import { hasWindow } from 'common/hasWindow';
import { fadeUpProperties, translateY } from 'common/spring';
import AppContext from '../../common/context.app';
import { sendTrackingEvent } from '../../common/sendTrackingEvent';
import { ButtonDial } from '../button-dial';
import Styles from './header.module.css';

const Navigation = props => {
  const { reducedMotion } = useContext(AppContext);
  const animation = useTrail(NAVIGATION_ITEMS.length, {
    ...fadeUpProperties,
    from: { y: -16, opacity: 0 },
    config: { mass: 1, tension: 160, friction: 16 },
    immediate: reducedMotion,
  });

  return (
    <nav className={Styles.hamburgerContainer}>
      <ul className={Styles.list}>
        {NAVIGATION_ITEMS.map((item, index) => {
          if (index === 0) return null;
          const partiallyActive = hasWindow ? window.location.pathname.startsWith(item.route) : false;
          const opacity = animation[index].opacity;
          const transform = animation[index].y.interpolate(translateY);
          return (
            <animated.li
              key={item.route}
              className={Styles.item}
              style={{ transform, opacity }}
            >
              <Link
                to={item.route}
                className={Styles.link}
                partiallyActive={partiallyActive}
                activeClassName={Styles.active}
                onClick={() => sendTrackingEvent('main-navigation', 'click', item.body)}
              >
                {item.body}
              </Link>
            </animated.li>
          );
        })}
      </ul>
      <ButtonDial/>
    </nav>
  );
};

const Hamburger = ({ pressed, ...otherProps }) => (
  <button {...otherProps} type="button" className={Styles.hamburger}>
    <span className="visually-hidden">{pressed ? 'Close' : 'Open'}</span>
    <span>
      <span className={Styles.bun}/>
      <span className={Styles.bun}/>
    </span>
  </button>
);

const Header = () => {
  const [navOpen, setNavOpen] = useState(false);
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const elementRef = useRef(null);

  useEffect(() => {
    // Close the drawer if its open AND we resize to different dimensions;
    if (isTablet && navOpen) setNavOpen(false);
  }, [navOpen, isTablet]);

  const preventBodyTouchMove = useCallback(event => {
    event.preventDefault();
  }, []);

  useEffect(() => {
    const element = elementRef.current;
    const blockingElements = document.$blockingElements;

    if (navOpen) {
      if (blockingElements) blockingElements.push(element);

      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
      document.body.addEventListener('touchmove', preventBodyTouchMove, {
        passive: false,
      });
    } else {
      if (blockingElements) blockingElements.remove(element);
      document.body.style.removeProperty('overflow');
      document.body.removeEventListener('touchmove', preventBodyTouchMove);
    }

    return () => {
      if (blockingElements && element) blockingElements.remove(element);
    };
  }, [preventBodyTouchMove, navOpen]);

  let showDrawer = true;
  let content = (
    <div className={`${Styles.hamburgerContainer} ${navOpen ? Styles.dialBlack : ''}`}>
      <ButtonDial/>
      <Hamburger pressed={navOpen} onClick={() => setNavOpen(!navOpen)}/>
    </div>
  );
  if (isDesktop) {
    content = <Partners className={Styles.partners}/>;
    showDrawer = false;
  } else if (isTablet) {
    content = <Navigation/>;
    showDrawer = false;
  }

  const containerClasses = 'nhc-container-left nhc-container-left--mobile-only nhc-container-right';
  const drawerActiveClass = navOpen ? Styles.drawerActive : '';

  return (
    <div
      className={`${containerClasses} ${Styles.header} ${drawerActiveClass}`}
      ref={elementRef}
    >
      <div className={Styles.inner}>
        <Link
          to="/"
          aria-label="go back to homepage"
          onClick={() => {
            if (navOpen) setNavOpen(false);
            sendTrackingEvent('main-navigation', 'click', 'Carnival Sounds Logo');
          }}
        >
          <Logo className={Styles.logo}/>
        </Link>
        <div className={Styles.dynamic}>{content}</div>
      </div>

      {showDrawer && (
        <NavigationMobile
          open={navOpen}
          onItemClick={() => {
            if (navOpen) setNavOpen(false);
          }}
        />
      )}
    </div>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
