export function sendTrackingEvent(
  // string - required - The object that was interacted with (e.g.video)
  category,
  // string - required - Type of interaction (e.g. 'play')
  action,
  // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
  label = '',
  // number - optional - Numeric value associated with the event. (e.g. A product ID)
  value = ''
) {
  const data = {};

  if (category) data.event_category = category;
  if (label) data.event_label = label;
  if (value) data.value = value;

  typeof window !== 'undefined' && window.gtag('event', action, { ...data });
}
