import Partners from '../svgs/partners';
import React from 'react';
import Styles from './footer.module.css';
import { sendTrackingEvent } from '../../common/sendTrackingEvent';
import { useIsTablet } from '../../common/hooks/useIsTablet';

export const ITEMS = [
  {
    body: 'Legal',
    url: 'https://www.spotify.com/uk/legal/end-user-agreement/',
  },
  {
    body: 'Privacy',
    url: 'https://www.spotify.com/uk/legal/privacy-policy/',
  },
  {
    body: 'Cookies',
    url: 'https://www.spotify.com/uk/legal/cookies-policy/',
  },
];

const Footer = () => {
  const isTablet = useIsTablet();

  return (
    <footer className={Styles.footer}>
      <div
        className={`nhc-container-right nhc-container-left ${Styles.layout}`}
      >
        {isTablet && (
          <div className={Styles.sponsors}>
            <Partners />
          </div>
        )}
        <nav>
          <ul className={Styles.list}>
            {ITEMS.map(({ body, url }) => (
              <li key={body} className={Styles.item}>
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => sendTrackingEvent('footer', 'click', body)}
                >
                  <span>{body}</span>
                </a>
              </li>
            ))}
          </ul>
        </nav>
        <span className={Styles.copyright}>© 2023 Spotify AB</span>
      </div>
    </footer>
  );
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
