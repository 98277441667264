import React, { Component, createContext } from 'react';

const AppContext = createContext(false);

export class AppContextProvider extends Component {
  state = {
    currentPage: '/',
    setCurrentPage: currentPage => {
      this.setState({ currentPage }, () => {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            // https://github.com/Stinkstudios/spotify-nhc-issues/issues/25
            if (document.scrollingElement.scrollTop > 0) window.scrollTo(0, 0);
          });
        });
      });
    },
    hasNavigated: false,
    setHasNavigated: hasNavigated => this.setState({ hasNavigated }),
    introActive: true,
    setIntroActive: introActive => this.setState({ introActive }),
    reducedMotion: 'true',
    setReducedMotion: reducedMotion => this.setState({ reducedMotion }),
    isHoldingPageActive: false,
    hasBackgroundNoiseTexture: false,
  };

  componentDidMount() {
    const isHome = window.location.pathname === '/';
    if (isHome && !this.state.introActive && !this.state.hasNavigated) {
      this.setState({
        introActive: true,
        hasNavigated: true,
      });
    }

    if (!isHome && !this.state.isHoldingPageActive) {
      this.state.setIntroActive(false);
    }

    window.addEventListener('keydown', this._handleKeydown);
    this._applyReducedMotionCSS();
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this._handleKeydown);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.reducedMotion !== this.state.reducedMotion) {
      this._applyReducedMotionCSS();
    }
  }

  _applyReducedMotionCSS = (reducedMotion = this.state.reducedMotion) => {
    if (this.state.reducedMotion) {
      document.body.classList.add('reduced-motion');
    } else {
      document.body.classList.remove('reduced-motion');
    }
  };

  _handleKeydown = event => {
    if (event.key === 'Escape') {
      this.setState({ reducedMotion: !this.state.reducedMotion });
    }
  };

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export default AppContext;
