import React, { useContext } from 'react';

import DynamicHeading, { HEADING_OPTIONS } from '../dynamic-heading';
import AppContext from 'common/context.app';
import CarnivalHeadline from './carnival-headline';
import PropTypes from 'prop-types';

import Styles from './carnival-title.module.css';
import { animated } from 'react-spring';
import { getAnimation } from '../introduction';

const CarnivalTitle = props => {
  const anim = getAnimation(props.animations[3]);
  const { isHoldingPageActive } = useContext(AppContext);

  return (
    <div className={Styles.carnivalTitle}>
      <DynamicHeading tag={HEADING_OPTIONS.H1} className="visually-hidden">
        Carnival Sounds
      </DynamicHeading>
      <button
        className={`${Styles.button} ${
          isHoldingPageActive && Styles.isHoldingPage
        }`}
        onClick={props.onClick}
        aria-label="Enter site"
        style={{ color: props.theme.color }}
      >
        <animated.span
          style={{
            color: props.theme.color,
            opacity: anim.opacity,
            transform: anim.transform,
          }}
          className={Styles.presents}
        >
          <span>Spotify</span> presents
        </animated.span>

        <CarnivalHeadline
          sun={props.theme.sun}
          animations={props.animations}
          active={props.active}
          aria-hidden="true"
        />

        <animated.span
          style={{
            color: props.theme.color,
            opacity: anim.opacity,
            transform: anim.transform,
          }}
          className={Styles.date}
        >
          2023
        </animated.span>
      </button>
    </div>
  );
};

CarnivalTitle.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

export default CarnivalTitle;
