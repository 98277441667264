import React from 'react';
import PropTypes from 'prop-types';
import Styles from './skip-to-main-content.module.css';

const SkipToMainContent = ({ body, contentId }) => (
  <a href={`#${contentId}`} className={Styles.skipToMainContent}>
    {body}
  </a>
);

SkipToMainContent.propTypes = {
  contentId: PropTypes.string.isRequired,
  body: PropTypes.string,
};

SkipToMainContent.defaultProps = {
  body: 'Skip to main content',
};

export default SkipToMainContent;
