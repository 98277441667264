import { translate } from "common/spring";

const FRAMERATE = 3;
const UNIT = "%";

class BackgroundNoise {
	constructor(element) {
		this._element = element;
		this._active = false;
		this._steps = 0;
	}

	_start() {
		if (!this._active) this._active = true;
		this.loop();
	}

	_stop() {
		if (this._active) this._active = false;
	}

	set element(element) {
		this._element = element;
	}

	set active(active) {
		this._active = active;

		this._active ? this._start() : this._stop();
	}

	loop = () => {
		if (!this._active) return;
		this._steps += 1;

		if (this._steps > FRAMERATE) {
			this._steps = 0;
			const x = -25 + Math.random() * 10;
			const y = -25 + Math.random() * 10;
			this._element.style.transform = translate(x, y, UNIT);
		}

		window.requestAnimationFrame(this.loop);
	};
}

export default BackgroundNoise;
