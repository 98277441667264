import React, { Component, createContext } from 'react';

export const THEMES = {
  dark: {
    backgroundColor: 'var(--black)',
    color: 'var(--white)',
    sun: 'var(--white)',
  },
  orange: {
    color: 'var(--yellow-bright)',
    backgroundColor: 'var(--orange)',
    sun: 'var(--light-orange)',
  },
  parisDaisy: {
    backgroundColor: 'var(--paris-daisy)',
    color: 'var(--red-orange)',
    sun: 'var(--sunshade)',
  },
  caribbeanGreen: {
    backgroundColor: 'var(--caribbean-green)',
    color: 'var(--yellow)',
    sun: 'var(--red-orange)',
  },
  pink: {
    backgroundColor: 'var(--pink)',
    color: 'var(--red-orange)',
    sun: 'var(--biloba-flower)',
  },
  bilobaFlower: {
    backgroundColor: 'var(--biloba-flower)',
    color: 'var(--regal-blue)',
    sun: 'var(--pink)',
  },
  outrageousOrange: {
    backgroundColor: 'var(--outrageous-orange)',
    color: 'var(--pink)',
    sun: 'var(--morning-glory)',
  },
  redOrange: {
    backgroundColor: 'var(--red-orange)',
    color: 'var(--sunshade)',
    sun: 'var(--yellow)',
  },
  lochinvar: {
    backgroundColor: 'var(--lochinvar)',
    color: 'var(--paris-daisy)',
    sun: 'var(--red-orange)',
  },
  morningGlory: {
    backgroundColor: 'var(--morning-glory)',
    color: 'var(--lochinvar)',
    sun: 'var(--paris-daisy)',
  },
};

const ThemeContext = createContext(THEMES.dark);

export class ThemeContextProvider extends Component {
  state = {
    theme: THEMES.caribbeanGreen,
    setTheme: theme => {
      if (theme.backgroundColor !== this.state.theme.backgroundColor) {
        this.setState({ theme });
      }
    },
    landingTheme: THEMES.caribbeanGreen,
    setLandingTheme: landingTheme => {
      if (
        landingTheme.backgroundColor !== this.state.landingTheme.backgroundColor
      ) {
        this.setState({ landingTheme });
      }
    },
  };

  render() {
    return (
      <ThemeContext.Provider value={this.state}>
        {this.props.children}
      </ThemeContext.Provider>
    );
  }
}

export default ThemeContext;
