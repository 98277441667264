export const APP_ICONS = [
  {
    src: '/favicons/appicon.svg',
    type: 'image/svg+xml',
    rel: 'icon',
  },
  {
    src: '/favicons/favicon.ico',
    type: 'image/x-icon',
    sizes: '16x16 32x32 48x48',
    rel: 'shortcut icon',
  },
  {
    src: '/favicons/favicon-16x16.png',
    type: 'image/png',
    sizes: '16x16',
    rel: 'icon',
  },
  {
    src: '/favicons/favicon-32x32.png',
    type: 'image/png',
    sizes: '32x32',
    rel: 'icon',
  },
  {
    src: '/favicons/favicon-48x48.png',
    type: 'image/png',
    sizes: '48x48',
    rel: 'icon',
  },
  {
    src: '/favicons/android-chrome-192x192.png',
    type: 'image/png',
    sizes: '192x192',
    rel: 'icon',
  },
  {
    src: '/favicons/android-chrome-512x512.png',
    type: 'image/png',
    sizes: '512x512',
    rel: 'icon',
  },
  {
    src: '/favicons/apple-touch-icon.png',
    type: 'image/png',
    sizes: '180x180',
    rel: 'apple-touch-icon',
  },
  {
    src: '/favicons/safari-pinned-tab.svg',
    sizes: '512x512',
    rel: 'mask-icon',
    color: '#191414',
  },
];
